import { useForm, Form, Input, Row, Col, Create, Select, Switch, InputNumber, useSelect } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { CardAdmin } from "components/Card/CardAdmin";
import React, { useState } from "react";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { ModeTime } from "types/activity";
import { UploadImage } from "components/Upload/Upload";
import { IDimension } from "types/dimension";
import { useMe } from "context/MeContext";
import { dataTypeOps } from "../Challenge/edit";
import { ListActivityResult } from "./components/ListActivityResult";

export const activityOps = [
  {
    value: ModeTime.DAILY,
    label: ModeTime.DAILY,
  },
  {
    value: ModeTime.WEEKLY,
    label: ModeTime.WEEKLY,
  },
];

export const ActivityCreate: React.FC = () => {
  const { push } = useNavigation();
  const [disabledHide, setDisabledHide] = useState(false);
  const [disabledRecommend, setDisabledRecommend] = useState(false);

  const { company, isSuperAdmin } = useMe();
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    resource: "activity",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/activities/activity");
    },
  });
  const [loading, setLoading] = useState(false);
  const { selectProps: dimensionProps } = useSelect<IDimension>({
    resource: "dimension",
    optionLabel: "name",
  });

  const { selectProps, queryResult } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
    filters: !isSuperAdmin
      ? [
          {
            field: "id",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
  });

  const onChangeRecommendSwitch = (checked: boolean) => {
    disabledHide ? setDisabledHide(false) : setDisabledHide(true);
  };

  const onChangeHideSwitch = (checked: boolean) => {
    disabledRecommend ? setDisabledRecommend(false) : setDisabledRecommend(true);
  };

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={queryResult.isLoading}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          company_id: company?.id,
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <CardAdmin title="General information">
              <Form.Item
                label={"Order"}
                name="order"
                className="w-[200px]"
                rules={[
                  {
                    required: true,
                    message: "Order must be greater than or equal 0",
                  },
                ]}
              >
                <InputNumber type={"number"} min={0} />
              </Form.Item>
              <Form.Item
                label={"Company"}
                name={"company_id"}
                rules={[
                  {
                    required: true,
                  },
                ]}
                className="w-full"
              >
                <Select disabled={!isSuperAdmin} {...selectProps} placeholder="Company" />
              </Form.Item>
              <Form.Item
                className="w-100"
                label={"Activity"}
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div className="flex space-x-2">
                <Form.Item
                  className="w-1/2"
                  label={"Dimension"}
                  name="dimensionId"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select {...dimensionProps} />
                </Form.Item>
              </div>
              <div className="flex space-x-2">
                <Form.Item
                  label={"Icon"}
                  name="icon"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <UploadImage setLoading={setLoading} loading={loading || formLoading} form={form} isDefault={false} />
                  <div>
                    Icon Library:{" "}
                    <a href="https://fontawesome.com/search" target="_blank" rel="noopener noreferrer">
                      click here
                    </a>
                  </div>
                </Form.Item>
              </div>

              <Form.Item
                label={"Description"}
                name="description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item label={"Recommend"} name="isRecommend" className="w-[200px]">
                <Switch disabled={disabledRecommend} onChange={onChangeRecommendSwitch} />
              </Form.Item>
              <Form.Item label={"Hide"} name="isHide" className="w-[200px]" valuePropName="checked">
                <Switch disabled={disabledHide} onChange={onChangeHideSwitch} />
              </Form.Item>
            </CardAdmin>
          </Col>
          <Col xs={24} lg={8}>
            <CardAdmin title="Levels">
              <Form.List name={"levelActivities"}>
                {(fields, { add, remove }, { errors }) => (
                  <div>
                    {fields.map((field) => {
                      return (
                        <div key={field.key} className="relative">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "name is required!",
                              },
                            ]}
                            label={`Name`}
                            name={[field.name, "name"]}
                            className="w-full pr-[30px]"
                          >
                            <Input className="font-normal" />
                          </Form.Item>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Description is required!",
                              },
                            ]}
                            label={`Data Type`}
                            name={[field.name, "data_type"]}
                            className="w-full pr-[30px]"
                          >
                            <Select options={dataTypeOps} className="font-normal" />
                          </Form.Item>
                          <h4>Results</h4>
                          <ListActivityResult field={field} />
                          <CloseCircleFilled
                            className="absolute right-[5px] top-[35px] text-red-400 text-[20px] cursor-pointer"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </div>
                      );
                    })}
                    <p className="flex justify-end h-[22px] text-red-700">{errors}</p>

                    {fields.length < 10 && (
                      <div onClick={() => add()} className="cursor-pointer flex justify-end">
                        <PlusOutlined className="mr-2" />
                        Add more
                      </div>
                    )}
                  </div>
                )}
              </Form.List>
            </CardAdmin>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
