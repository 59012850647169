import React, {useState, useEffect} from 'react';
import { Tabs } from 'antd';
import { useMe } from "context/MeContext";
import { useList } from '@pankod/refine-core';
import "../adminDashboard.scoped.scss";
import MedianWellnessScoreByDemographic from '../Charts/median-wellness-score-by-demographic';
import ScoringByDimension from '../Charts/scoring-by-dimension';
import MedianWellNessScore from '../Charts/median-wellness-score';
import { css } from '@emotion/react';
import GenderChart from '../Charts/gender-chart';
import Image from "../../../../assets/img/oct10.jpg"
import Image1 from "../../../../assets/img/Save.png";
import ChallengeParticipated from '../Charts/challenge-participated';
import AllPartipipants from '../Charts/all-participants';
import ChallengeCompleted from '../Charts/challenge-completed';
import ExampleLine from '../Charts/example-line';
import Users1 from "../../../../assets/img/users.svg"
import dayjs from 'dayjs';
import { Select} from 'antd';
import {
  RadialBarChart,
  RadialBar,
  Legend,
  PolarRadiusAxis,
  PolarAngleAxis,
} from "recharts";

type Challenge = {
  joined_users: number;
  participating_users: number;
  challenge_id: number;
  challenge: string;
  start_date: string;
};
const { TabPane } = Tabs;
const data = [
  {
    name: "participated",
    percent: 80,
    fill: "#005E83",
  },
];

export const ChallengeParticipation: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  const {data: dataChallengeJoined} = useList({
    resource: "challenge-joined",
    config: {
      filters: [
       {
        field: "code",
        operator: "eq",
        value: company?.code,
       } 
      ],
      sort: [
        {
          field: "start_date",
          order: "desc",
        }
      ]
    }
  })
  
  const challengeJoined = dataChallengeJoined?.data || []
  const rawStartDate = challengeJoined[0]?.start_date || "";
  const formattedDate = rawStartDate
    ? new Intl.DateTimeFormat('en-GB').format(new Date(rawStartDate))
    : "N/A"; // Default value if start_date is invalid or missing

  // const [selectedChallenge, setSelectedChallenge] = useState<{
  //   joined_users: number;
  //   participating_users: number;
  //   challenge_id: number;
  //   challenge: string;
  //   start_date: string,
  // }>({
  //   joined_users: challengeJoined[0]?.joined_users,
  //   participating_users: challengeJoined[0]?.participating_users,
  //   challenge_id: challengeJoined[0]?.challenge_id ,
  //   challenge: challengeJoined[0]?.challenge,
  //   start_date: formattedDate,
  // });

  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(null);

  useEffect(() => {
      if (dataChallengeJoined?.data && dataChallengeJoined.data.length > 0) {
        const initialChallenge = dataChallengeJoined.data[0];
        console.log("Setting initial challenge:", initialChallenge);
        
        setSelectedChallenge({
          joined_users: initialChallenge.joined_users,
          participating_users: initialChallenge.participating_users,
          challenge_id: initialChallenge.challenge_id,
          challenge: initialChallenge.challenge,
          start_date: dayjs(initialChallenge.start_date).format('DD/MM/YYYY'),
        });
      }
  }, [dataChallengeJoined?.data]);

  const handleChange = (value:string) => {
    const challenge = challengeJoined?.find((item) => item.challenge === value);
    if (challenge) {
      setSelectedChallenge({
        joined_users: challenge.joined_users,
        participating_users: challenge.participating_users,
        challenge_id: challenge.challenge_id,
        challenge: challenge.challenge,
        start_date: dayjs(challenge.start_date).format('MM/DD/YYYY'),
      });
    }
  }

  const defaultChallenge = challengeJoined[0]?.challenge || ''; 
  //console.log("default:::",defaultChallenge)
  //console.log(selectedChallenge?.start_date)
  //console.log("challenge joined",challengeJoined)
  return (
    <div className='tab tab1' css={css`
        .recharts-wrapper {
          margin: auto !important;
          align-items: center;
        }
        .box {
          width: 23%
        }
        .box2 {
          width: 29%;
        }
        .box0 {
          width: 50%;
          padding: 20px;
        }
        select {
          min-width:100px;
        }  
    `}>
      <div className="middle">
        <div className="single-box j-between challenge-title-group">
          <div className="left-challenge">
            {selectedChallenge && (<div className='challenge-title no-padding'>{selectedChallenge?.challenge}</div>)}
            <div className='challenge-title no-padding'></div>
            {
              selectedChallenge?.start_date!=="N/A" 
                ? <p style={{ paddingTop: 5 }}>Date started: {selectedChallenge?.start_date}</p> 
                : <p style={{color:"#83312e", fontSize: 20}}>Please choose a challenge</p>
            }
          </div>
          <div className="right-challenge">
            <Select
              placeholder="Choose a Challenge"
              style={{ width: "auto", minWidth: "200px"}}
              onChange={handleChange}
              defaultValue={defaultChallenge || undefined}
            >{challengeJoined?.map((item) => (
              <Select.Option key={item?.id} value={item?.challenge}>
                {item?.challenge}
              </Select.Option>
            ))}
          </Select>
          </div>
        </div>
      </div>
      <br/>
      {
        selectedChallenge?.start_date==="N/A" 
          ? "" 
          : (
            <>
              <div className="top j-between">
                <div className="single-box box0">
                  <div className="toptop j-between">
                    <div className='toptop-chart'>
                      <h2 className='no-padding-all'>Joined</h2>
                      {selectedChallenge && (<h1 className='t-center joined'>{selectedChallenge?.joined_users}</h1>)}
                      {/* <h1 className='t-center joined'>233</h1> */}
                    </div>
                    {/* <div><ExampleLine/></div> */}
                    <div className="vt"></div>
                    <div className='topbot-chart' >
                      <h2 className='no-padding-all'>Participated</h2>
                      <RadialBarChart
                        width={250}
                        height={260}
                        cx="50%"
                        cy="45%"
                        innerRadius={100}
                        outerRadius={150}
                        barSize={20}
                        data={data}
                        startAngle={180} 
                        endAngle={0}
                      >

                        <RadialBar
                          label={({ cx, cy, value }) => (
                            <>
                              <image
                                href={Users1}  
                                x={cx - 30 / 2}  
                                y={cy - 60}  
                                height={30}  
                                width={30} 
                                //clipPath="circle(50%)"
                                style={{
                                  filter: "grayscale(100%) brightness(60%)", 
                                  opacity: 0.3,
                                }}
                              />
                              {selectedChallenge && (
                                <text
                                x={cx}
                                y={cy-15}
                                fill="rgba(124, 124, 124, 0.7)"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize={15}
                                fontWeight={400}
                              >
                                {selectedChallenge && (selectedChallenge?.participating_users)} Total users
                              </text>
                              )}
                              <text
                                x={cx}
                                y={cy+20}
                                fill="#005E83"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize={35}
                                fontWeight={400}
                              >
                                {/* {`${value}%`} */}
                                {selectedChallenge && (Math.ceil(100/selectedChallenge?.joined_users*selectedChallenge?.participating_users) || 0)}%
                              </text>
                            </>
                          )}
                          background
                          //clockWise
                          dataKey="percent"
                          // max
                        />
                        <PolarAngleAxis
                          tick={false}
                          domain={[0, 100]}
                          type="number"
                        />
                      </RadialBarChart>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="bottom">
                <div className="single-box">
                  <AllPartipipants selectedChallenge={selectedChallenge}/>
                </div>
              </div>
            </>
          )
      }

    </div>
  );
};

export default ChallengeParticipation;
