import React, {useRef} from 'react';
import { css } from '@emotion/react';
import { Table, Button } from 'antd';
import type { TableColumnsType } from 'antd';
import { createStyles } from 'antd-style';
import { useList} from '@pankod/refine-core';
import { useMe } from "context/MeContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../adminDashboard.scoped.scss"
import * as XLSX from 'xlsx';
import { ExportOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
library.add(faDownload);
interface DataType {
  key?: React.Key;
  first_name: string;
  last_name: string;
  email: string;
  points: number;
}
const useStyle = createStyles(({ css }) => {
    return {
      customTable: css`
        .ant-table {
          .ant-table-container {
            .ant-table-body,
            .ant-table-content {
              scrollbar-width: thin;
              scrollbar-color: unset;
            }
          }
        }
      `,
    };
  });

const data: TableColumnsType<DataType> = [
  // {
    // title: "ALL PARTICIPANTS",
    // children: [
      {
        title: 'No.',
        dataIndex: 'key',
        width: 70,
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        width: 200,
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        width: 200,
      },
    
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Point',
        dataIndex: 'points',
        width: 150,
        sorter: (a: DataType, b: DataType) => a.points - b.points, // Add this line for sorting
        sortDirections: ['ascend', 'descend'],
      },
    // ]
  // }
];

// const dataSource1 = Array.from({ length: 100 }).map<DataType>((_, i) => ({
//   key: i,
//   firstname: `first name ${i}`,
//   lastname: `last name ${i}`,
//   email: `firstname+lastname${i}@gmail.com`,
//   point: Math.floor(Math.random() * (100 - 5 + 1)) + 5,
// }));


const AllPartipipants: React.FC<{selectedChallenge:any}> = ({selectedChallenge}) => {
  const { styles } = useStyle();
  const { company } = useMe();
  const tableRef = useRef(null);
  // const {data: dataChallengeParticipants} = useList({
  //   resource: "challenge-participants",
  //   config: {
  //     pagination: {
  //       pageSize: data?.total,
  //     },
  //     sort: [
  //       {
  //         field: "id",
  //         order: "asc"
  //       }
  //     ],
  //     filters: [
  //       {
  //         field: "code",
  //         operator: "eq",
  //         value: company?.code
  //       }
  //     ]
  //   }
  // })
  const { data: allParticipants } = useList({
    resource: "challenge-participants",
    config: {
      pagination: {
        current: 1,
        pageSize: 10  // First call to get the total
      },
      sort: [
        {
          field: "id",
          order: "asc"
        }
      ],
      filters: [
        {
          field: "code",
          operator: "eq",
          value: company?.code
        }
      ]
    }
  });
  
  // Get the total number from first response
  const totalParticipants = allParticipants?.total || 0;
  
  // Second call to get all records
  const { data: dataChallengeParticipants } = useList({
    resource: "challenge-participants",
    config: {
      pagination: {
        current: 1,
        pageSize: totalParticipants  // Use the total as pageSize
      },
      sort: [
        {
          field: "id",
          order: "asc"
        }
      ],
      filters: [
        {
          field: "code",
          operator: "eq",
          value: company?.code
        }
      ]
    }
  });
  console.log("challengeParticipants", dataChallengeParticipants)
  const challengeParticipants = dataChallengeParticipants?.data || []
  const dataSource: DataType[] = challengeParticipants
    .filter((item) => {
      console.log("Comparing:", {
        itemChallengeId: item?.challenge_id,
        selectedChallengeId: selectedChallenge?.challenge_id
      });
      return item?.challenge_id === selectedChallenge?.challenge_id;
    })
    .map((item, index) => ({
      key: index+1,
      first_name: item.first_name || "",
      last_name: item.last_name || "",
      email: item.email || "",
      points: item.points || 0,
    }));

  console.log("dataSource",dataSource)
  
  const ExportPDF = () => {
    if (!dataSource) return;
    const doc = new jsPDF();
    doc.text("All Participants", 14, 10);
    const tableColumn = ["No.","First Name", "Last Name", "Email", "Point"]
    const tableRows: any[] = [];
    dataSource?.forEach((item: any) => {
      const rowData = [item.key, item.first_name, item.last_name, item.email, item.points];
      tableRows.push(rowData);
    });
    (doc as any).autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });
    doc.save("participant_list.pdf");
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataSource);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate buffer and save the file
    XLSX.writeFile(workbook, 'all-participants.xlsx');
  };
  
  const title = () => (
    <div className="table-header">
      <span className="table-title">ALL PARTICIPANTS</span>
      <Button className="export-button" onClick={ExportPDF} style={{marginRight:10}}>
        <FontAwesomeIcon icon={faDownload} className="icon" /> PDF
      </Button>
      <Button className="export-button" onClick={exportToExcel}>
        <FontAwesomeIcon icon={faDownload} className="icon" /> Excel
      </Button>
    </div>
  );
  return (
    <div css={css`
      .icon {
      margin-right: 10px;
      }
    `}>
      <Table<DataType>
        className={styles.customTable}
        columns={data}
        dataSource={dataSource}
        //pagination={{ pageSize: 10 }}
        scroll={{ y: 55 * 5 }}
        title={title}
      >
      </Table>
    </div>
  );
};

export default AllPartipipants;
