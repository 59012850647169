import React from "react";
import dayjs from "dayjs";
import "./hra.scoped.scss";
import { StarFilled, HomeFilled, RetweetOutlined } from "@ant-design/icons";
import { useCreate, useList, useNavigation } from "@pankod/refine-core";
import { GenerateQuestionList } from "components/Question/GenerateQuestionList";
import GenerateQuestionListRes from "components/QuestionRes/GenerateQuestionListRes";
import { Button, Form, Popconfirm, Modal, Spin } from "@pankod/refine-antd";
import { useMe } from "context/MeContext";
import { useCompany } from "context/CompanyContext";
import { css } from "@emotion/react";
import { BackTop } from "antd";
import { generateURL } from "utils/generateURL";

export interface UserAnswerList {
  id: number;
  choice: string[];
  text?: string;
}

const HRA: React.FC = () => {
  const { push } = useNavigation();
  const [open, setOpen] = React.useState(false);
  const [openRedirectModal, setOpenRedirectModal] = React.useState(false);

  const [userAnswerList, setUserAnswerList] = React.useState<UserAnswerList[]>([]);
  const [form] = Form.useForm();
  const { me, company, myHRA } = useMe();
  const { hra, hra_summary, path: pathCompany } = useCompany();
  const main_color = hra_summary?.main_hra_color;
  React.useEffect(() => {
    if (!myHRA || openRedirectModal) return;
    setOpen(true);
  }, [myHRA, openRedirectModal]);

  const { data, isLoading } = useList({
    resource: "question",
    config: {
      pagination: {
        pageSize: 1000,
      },
      sort: [
        {
          field: "name",
          order: "asc",
        },
      ],
      filters: [
        {
          field: "parentQuestionId",
          operator: "eq",
          value: null,
        },
      ],
    },
    metaData: {
      join: {
        field: "childQuestions",
      },
    },
  });

  function onOk() {
    push(`/${pathCompany}`);
  }

  function onSubmit() {
    form.submit();
  }

  const { mutateAsync: submitAnswers } = useCreate();

  const questions = data?.data.filter((item) => {
    const metadata = item.metadata;
    const myAge = dayjs().year() - dayjs(me?.dateOfBirth).year();
    const myGender = me?.gender;
    const age = metadata?.ageToShow?.age || 0;
    const operator = metadata?.ageToShow?.operator;
    let shouldShow = true;
    if (metadata?.ageToShow?.enabled) {
      switch (operator) {
        case "eq":
          shouldShow = myAge === age;
          break;
        case "lte":
          shouldShow = myAge <= age;
          break;
        case "gte":
          shouldShow = myAge >= age;
          break;
        default:
          shouldShow = false;
          break;
      }
      if (!metadata?.ageToShow?.gender?.includes(myGender)) {
        shouldShow = false;
      }
    }
    return shouldShow;
  });
  const total = userAnswerList.length;

  React.useEffect(() => {
    if (userAnswerList.length === 0) {
      const defaultList: UserAnswerList[] =
        questions?.flatMap((question) => {
          const childQuestions = question.childQuestions;
          if (childQuestions.length > 0) {
            return childQuestions.map((child: any) => {
              return {
                id: child.id as number,
                text: "",
                choice: [],
              };
            });
          }
          return {
            id: question.id as number,
            text: "",
            choice: [],
          };
        }) || [];

      setUserAnswerList(defaultList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const userSelectedAnswers = userAnswerList.filter((item) => item.choice.length > 0 || item.text).length;

  const onRestart = () => {
    form.resetFields();
    setUserAnswerList((curr) => {
      return curr.map((item) => {
        return {
          ...item,
          text: "",
          choice: [],
        };
      });
    });
  };

  return (
    <Spin spinning={isLoading} size="large">
      <div
        className="hra-page pageload"
        css={css`
          .box-button {
            color: ${hra_summary?.main_hra_color};
            background-color: ${hra_summary?.background_color};
          }
          .icon,
          .icon1 {
            color: ${hra_summary?.main_hra_color};
          }
          .button-left {
            border-color: ${hra_summary?.main_hra_color};
            background-color: white !important;
          }
          .text1 {
            color: ${hra_summary?.main_hra_color} !important;
            strong {
              color: ${hra_summary?.main_hra_color} !important;
            }
          }
          .title-top {
            color: ${hra_summary?.title_color};
          }
        `}
      >
        <div>
          <BackTop />
        </div>
        <div className="container">
          <div className="img-top">
            <img style={{ borderRadius: 0 }} src={generateURL(hra?.image1)} alt="" />
          </div>
          <h2 className="page-title title-top">HEALTH RISK ASSESSMENT</h2>
          <div className="title text-center">
            <h2 className="employer-name">{hra?.employer_name}</h2>
            <h2 className="page-title title-bot">HEALTH RISK ASSESSMENT</h2>
            <p className="note-top" style={{ fontSize: 15, width: "70%", margin: "auto", lineHeight: 1.4 }}>
              <strong>Quick note: </strong>Welcome to Good Life! We are glad you stopped by. Before you get started,
              please complete the Health Risk Assessment. This quick survey is only required the first time you log in
              and once a year after that.
            </p>
          </div>
          <div className="content-middle">
            <div className="j-between">
              <div className="box-container box-left">
                <h5 className="box-button button-middle flex">
                  <HomeFilled className="icon" />
                  <div className="text1">
                    Number of questions: <strong>{total}</strong>
                  </div>
                </h5>
              </div>
              <div className="box-container box-right">
                <h5 className="box-button button-middle flex">
                  <StarFilled className="icon" />
                  <div className="text1">
                    Completed: <strong>{userSelectedAnswers}</strong>
                  </div>
                </h5>
              </div>
            </div>

            <div className="box-container fullwidth">
              <h5 className="box-button button-middle flex">
                <div className="text1">
                  <div
                    css={css`
                      color: ${hra_summary?.uncomplete_button_color};
                    `}
                  >
                    {userSelectedAnswers < total && "Please complete all the questions"}{" "}
                  </div>
                  <div>
                    {userSelectedAnswers === total && (
                      <div>
                        <StarFilled className="icon" />
                        <span className="text1">
                          You've completed all the questions. Click on Submit button to see your personal result!
                        </span>{" "}
                        <StarFilled className="icon" />
                      </div>
                    )}
                    {/* {" "} */}
                  </div>
                </div>
              </h5>
              {/* <p className="note-bottom" style={{ fontSize: 13, margin: "auto", lineHeight: 1.4, color: "gray" }}>
                <strong>Quick note: </strong>Welcome to Good Life! This quick survey is only required the first time you
                log in and once a year after that.
              </p> */}
            </div>
          </div>

          <div className="main-content j-between">
            <div className="content-left">
              <Popconfirm
                placement="topLeft"
                title={"Are you sure restart all answers?"}
                onConfirm={onRestart}
                okText="Yes"
                cancelText="No"
                okButtonProps={{ style: { background: main_color } }}
              >
                <button className="box-button button-left">
                  {/* <ReloadOutlined className="icon1" /> */}
                  <RetweetOutlined className="icon1" />
                  <span
                    className="text"
                    css={css`
                      color: ${hra_summary?.main_hra_color} !important;
                    `}
                  >
                    Restart
                  </span>
                </button>
              </Popconfirm>

              <Modal
                centered
                open={open}
                okText="Back to my dashboard"
                width={1000}
                closable={false}
                footer={
                  <div className="flex justify-between">
                    <div>
                      <Button
                        onClick={onOk}
                        type="primary"
                        className="text-white"
                        css={css`
                          background-color: ${hra_summary?.main_hra_color};
                          &:hover {
                            background-color: ${hra_summary?.background_color} !important;
                          }
                        `}
                      >
                        Back to my dashboard
                      </Button>
                    </div>
                  </div>
                }
              >
                <div className="modal-content">
                  <p>
                    Howdy, partner:
                    <br />
                    <span className="message">
                      {" "}
                      You're all caught up on your health check-in for the year. Give yourself a pat on the back and
                      keep up the great work. Your HRA will be back and ready for action on January 1st!
                    </span>
                  </p>
                </div>
              </Modal>
              <div>
                <img src={generateURL(hra?.image2)} alt="" className="image-left" />
              </div>
            </div>

            <div className="content-right">
              <div>
                <img style={{ borderRadius: 20 }} src={generateURL(hra?.image1)} alt="" className="img-right" />
              </div>
              <Form
                className="form-desktop"
                form={form}
                onFinish={async (e) => {
                  const userAnswers = userAnswerList.map((item) => {
                    const isQuestionHeight = questions?.some(
                      (q) =>
                        item.id === q.id && q.description.includes("height") && q.answer_detail?.items?.length === 1,
                    );
                    if (isQuestionHeight) {
                      const e0 = e["question-ft"];
                      const e1 = e["question-inch"] / 12;
                      return {
                        ...item,
                        text: (e0 + e1).toString(),
                      };
                    }
                    return item;
                  });
                  const res = await submitAnswers({
                    resource: "user-question",
                    values: {
                      userAnswers,
                      userId: me?.id,
                    },
                    successNotification: false,
                  });
                  if (res.data.id) {
                    setOpenRedirectModal(true);
                    setTimeout(() => {
                      window.location.href = `/${company?.path}/hraresults`;
                    }, 1000 * 5); //5s
                  }
                }}
              >
                <div className="question-bigbox">
                  <GenerateQuestionList form={form} questions={questions} setUserAnswerList={setUserAnswerList} />
                  <Button
                    loading={isLoading}
                    className="next-question-button box-button h-[60px] white-text"
                    onClick={onSubmit}
                    type="primary"
                    css={css`
                      background-color: ${hra_summary?.main_hra_color} !important;
                      &:hover {
                        background-color: ${hra_summary?.uncomplete_button_color} !important;
                      }
                    `}
                  >
                    SUBMIT
                  </Button>
                </div>
                <div className="questions-responsive" style={{ width: "100%" }}>
                  <GenerateQuestionListRes
                    form={form}
                    questions={questions}
                    userAnswerList={userAnswerList}
                    setUserAnswerList={setUserAnswerList}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Modal
          centered
          title={
            <div>
              <span>Redirecting</span>
              <Spin className="ml-2" spinning />
            </div>
          }
          open={openRedirectModal}
          footer={<></>}
          closable={false}
        >
          Congratulations on completing your HRA. Please hang on while we redirect you to your dashboard.
        </Modal>
      </div>
    </Spin>
  );
};

export default HRA;
